h4.modal-title{
    width: 100%;
    text-align:  center;
}

.block-ui-overlay.normal.new {
    opacity: 1;
    background-color: white;
}

.normal.new {
    background-color: white;
}

.alert.new {
    background-color: red;
}

.modal-success .block-ui-message {
    color: #4dbd74 !important;
    font-weight: bold;
}