.card{
    margin-bottom: 0 !important;
    margin: auto; 
}

.block-ui-overlay{
    opacity: 1;
    background-color: white;
}

.block-ui.normal .block-ui-overlay {
    opacity: 1;
    background-color: white;
}

.block-ui.normal .block-ui-message{
    color: black;
}


.block-ui-overlay .normal {
    opacity: 1;
    background-color: white;
}


.block-ui-overlay .alert {
    opacity: 1;
    background-color: red;
}

.block-ui .block-ui-message {
    color: #20a8d8;
    font-weight: bold;
}

.block-ui.alertMessage .block-ui-message {
    color: #000000;
    font-weight: bold;
}